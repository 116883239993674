<template>
    <div
        style="
            background: linear-gradient(296.82deg, #080808 0%, #353747 100%);
            font-family: Roboto;
        "
    >
        <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css"
            integrity="sha384-TX8t27EcRE3e/ihU7zmQxVncDAy5uIKz4rEkgIXeMed4M0jlfIDPvg6uqKI2xXr2"
            crossorigin="anonymous"
        />

        <div
            style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
            "
        >
            <div class="container">
                <div class="row">
                    <div
                        class="col-lg-12"
                        style="
                            background: #1b1b1d;
                            box-shadow: 0px 10px 55px rgba(6, 6, 6, 0.4);
                            border-radius: 5px;
                            padding-right: 24px;
                            padding-left: 24px;
                        "
                    >
                        <h1
                            style="
                                margin-top: 24px;
                                margin-bottom: 24px;
                                font-weight: 900;
                                font-size: 26px;
                                color: #b8b8b8;
                                line-height: 30px;
                                text-align: left;
                                letter-spacing: 0.05em;
                                text-transform: uppercase;
                            "
                        >
                            Contact
                        </h1>

                        <form @submit.prevent="sendEmail" id="contact-form">
                            <div class="controls">
                                <div class="row">
                                    <div class="col-md-6 custom-col-l">
                                        <div class="form-group">
                                            <input
                                                type="text"
                                                name="first_name"
                                                class="form-control"
                                                style="
                                                    background: #242429;
                                                    border-radius: 2px;
                                                    color: #ffffff;
                                                    border-color: #242429;
                                                    padding-left: 15px;
                                                "
                                                placeholder="First name"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6 custom-col-r">
                                        <div class="form-group">
                                            <input
                                                name="last_name"
                                                type="text"
                                                class="form-control"
                                                style="
                                                    background: #242429;
                                                    border-radius: 2px;
                                                    color: #ffffff;
                                                    border-color: #242429;
                                                    padding-left: 15px;
                                                "
                                                placeholder="Last name"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 custom-col-l">
                                        <div class="form-group">
                                            <input
                                                name="company"
                                                type="text"
                                                class="form-control"
                                                style="
                                                    background: #242429;
                                                    border-radius: 2px;
                                                    color: #ffffff;
                                                    border-color: #242429;
                                                    padding-left: 15px;
                                                "
                                                placeholder="Company name"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6 custom-col-r">
                                        <div class="form-group">
                                            <input
                                                name="from_email"
                                                type="text"
                                                class="form-control"
                                                style="
                                                    background: #242429;
                                                    border-radius: 2px;
                                                    color: #ffffff;
                                                    border-color: #242429;
                                                    padding-left: 15px;
                                                "
                                                placeholder="Email"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <textarea
                                                name="message"
                                                class="form-control"
                                                cols="20"
                                                rows="6"
                                                style="
                                                    padding-left: 15px;
                                                    padding-top: 10px;
                                                    background: #242429;
                                                    border-radius: 2px;
                                                    color: #ffffff;
                                                    border-color: #242429;
                                                    resize: none;
                                                "
                                                placeholder="Write a message here"
                                                required
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-12 text-right">
                                        <input
                                            type="submit"
                                            value="SEND"
                                            class=""
                                            style="
                                                background-color: transparent;
                                                color: #ffffff;
                                                letter-spacing: 0.1em;
                                                font-weight: 900;
                                                font-size: 20px;
                                                cursor: pointer;
                                                margin-bottom: 20px;
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import emailjs from "emailjs-com";
import $ from "jquery";

export default {
    created() {
        document.title = "Contact";
    },
    methods: {
        sendEmail: (e) => {
            console.log(e);
            emailjs
                .sendForm(
                    "service_personal",
                    "template_inst360",
                    e.target,
                    "user_aWdd68srMkIlKI5Yijhw5"
                )
                .then(
                    (result) => {
                        alert("Message sent successfully!");
                    },
                    (error) => {
                        console.log("FAILED...", error);
                    }
                );
            $("#contact-form").find("input[type=text], textarea").val("");
        },
    },
};
</script>

<style scoped>
input:focus,
textarea:focus {
    outline: none !important;
    box-shadow: none;
}
input::placeholder,
textarea::placeholder {
    color: rgba(255, 255, 255, 0.4) !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: all 5000s ease-in-out 0s !important;
}

@media (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 640px;
    }

    .custom-col-l {
        padding-right: 10px !important;
    }

    .custom-col-r {
        padding-left: 10px !important;
    }
}

@media (min-width: 992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 640px;
    }

    .custom-col-l {
        padding-right: 10px !important;
    }

    .custom-col-r {
        padding-left: 10px !important;
    }
}

@media (min-width: 768px) {
    .container,
    .container-md,
    .container-sm {
        max-width: 640px;
    }

    .custom-col-l {
        padding-right: 10px !important;
    }
    .custom-col-r {
        padding-left: 10px !important;
    }
}
</style>